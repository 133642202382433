import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

// Helper function to capitalize first letter of each word (for SEO title only)
const formatSeoTitle = (title) => {
  if (!title) return '';
  
  // Convert ALL CAPS title to Title Case format
  return 'Newsroom: ' + title.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export default function BlogPost({ data }) {
  // Fallback for missing data
  if (!data?.beehiivPost) {
    return (
      <Layout black={false}>
        <SEO title="Post Not Found" />
        <section className="py-15 py-xl-20 pb-xl-15">
          <div className="container mt-10">
            <h1>Post Not Found</h1>
            <p>Sorry, this post could not be loaded.</p>
          </div>
        </section>
      </Layout>
    );
  }

  const post = data.beehiivPost;
  
  // Format the title for SEO - convert from ALL CAPS to Title Case
  const seoTitle = formatSeoTitle(post.title);
  
  // Use preview_text for SEO description
  const seoDescription = post.preview_text || '';

  const imgContainerStyle = {
    width: '100%',
    paddingTop: '56.25%',
    position: 'relative',
    marginBottom: '3rem',
    overflow: 'hidden',
    backgroundColor: 'white'
  };

  const placeholderImgStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  };

  // Custom styles to ensure text is white and properly spaced
  const contentStyle = {
    color: 'white',
    marginBottom: '1.5rem' // Add bottom spacing after content
  };

  const titleSectionStyle = {
    marginBottom: '-3.5rem' // Add more space between title and image
  };

  // 1) Strip out Beehiiv's "Powered by beehiiv" footer div:
  const sanitizedHTML = (post.rss_content ?? "").replace(
    /<div class='beehiiv__footer'[^>]*>[\s\S]*?<\/div>/,
    ""
  );
  
  // 2) Ensure all text is white by wrapping in a div with white text color
  const whiteTextHTML = sanitizedHTML.replace(
    /<p/g, '<p style="color: white;"'
  ).replace(
    /<h\d/g, '<h$& style="color: white;"'
  ).replace(
    /<li/g, '<li style="color: white;"'
  ).replace(
    /<a/g, '<a style="color: #4db4d7;"'
  );

  return (
    <Layout black={false}>
      <SEO 
        title={seoTitle}
        description={seoDescription}
      />

      {/* SECTION 1: Post Title & Date */}
      <section className="py-15 py-xl-20 pb-xl-15" style={titleSectionStyle}>
        <div className="container mt-5">
        <span className="badge bg-muted-purple text-white rounded-pill mb-2">
          Newsroom
          </span>
          <h1 style={{ color: 'white' }}>
            {post.title}
          </h1>
          <p style={{ color: 'white' }}>{post.date}</p>
        </div>
      </section>

      <div style={imgContainerStyle}>
        {post.thumbnail_url ? (
          <img src={post.thumbnail_url} alt="Thumbnail" style={placeholderImgStyle} />
        ) : (
          <div style={placeholderImgStyle} className="bg-secondary"></div>
        )}
      </div>

      {/* SECTION 2: Main Post Content */}
      <section style={contentStyle}>
        <div className="container">
          <div className="row g-4 justify-content-between">
            <div className="col-md-12">
              {/* Dangerously set the sanitized and color-fixed HTML */}
              <div
                dangerouslySetInnerHTML={{ __html: whiteTextHTML }}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    beehiivPost(slug: { eq: $slug }) {
      title
      date
      rss_content
      thumbnail_url
      preview_text
    }
  }
`;